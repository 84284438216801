<template>
  <div ref="ModalBoxRef">
    <a-modal
      v-model:visible="visible"
      :onCancel="handleCancel"
      @ok="handleOk"
      :getContainer="() => ModalBoxRef"
      width="1000px"
      centered
    >
      <p class="title">{{ isEdit ? '编辑' : '新增' }}角色</p>
      <a-divider />
      <template #footer>
        <a-button key="back" @click="handleCancel">取消</a-button>
        <a-button key="submit" type="primary" :loading="loading" @click="handleOk">保存</a-button>
      </template>
      <a-form :label-col="labelCol" :wrapper-col="wrapperCol">
        <a-form-item label="角色名称">
          <a-input v-model:value.trim="roleName" placeholder="请输入用户名称" allowClear></a-input>
        </a-form-item>
        <a-form-item label="权限分配">
          <a-table
            :columns="authColumns"
            :dataSource="authTableData"
            :row-key="record => record.id"
            :hideDefaultSelections="true"
            :row-selection="{
              columnTitle: ' ',
              selectedRowKeys: auth,
              onChange: onAuthChange,
              onSelect: onAuthSelect,
              hideDefaultSelections: true
            }"
            :pagination="false"
            :scroll="{ y: 100 }"
          >
            <template #functionList="row">
              <a-checkbox-group
                v-if="checkedFunctionList.length"
                @change="handleChangeCheckbox(allFunctionList[row.record.index].groupId, row.record.index)"
                v-model:value="checkedFunctionList[row.record.index].itemIdList"
                :options="row.record.functionList"
              />
            </template>
          </a-table>
        </a-form-item>
        <a-form-item label="资源分配">
          <a-tree
            v-model:checkedKeys="checkedKeys"
            checkable
            :tree-data="treeData"
            :replaceFields="replaceFields"
            @check="onCheckTree"
          />
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>
<script>
import { defineComponent, onMounted, ref, reactive, toRefs, watch, computed, nextTick } from 'vue';
import { message } from 'ant-design-vue';
import api from '@/services';
import constant from '@/utils/common';

const authColumns = [
  {
    title: '菜单名称',
    dataIndex: 'menuName',
    align: 'center',
    width: '20%'
  },
  {
    title: '功能',
    dataIndex: 'functionList',
    align: 'center',
    width: '80%',
    slots: {
      customRender: 'functionList'
    }
  }
];
// 替换数组建原有字段
const replaceFields = {
  children: 'children',
  title: 'resourceName',
  key: 'key'
};

export default defineComponent({
  setup(props, context) {
    const loading = ref(false);
    const visible = ref(false);

    const isEdit = ref(false);
    const ModalBoxRef = ref();

    // 表单相关定义
    const labelCol = reactive({ span: 4, offset: 1 });
    const wrapperCol = reactive({ span: 18 });
    const formState = reactive({
      roleName: '',
      id: ''
    });

    // 表格相关定义
    const authTableData = reactive([]);
    const treeData = ref([]);
    const tableState = reactive({
      resourceTableData: constant.resourceTypeList,
      authTableData: []
    });

    // 选中的 tree node key
    const checkedKeys = ref([]);

    const selectedRowKeys = reactive({
      auth: []
    });

    const checkedFunctionList = ref([]); // 权限分配 => 某个页面 => checked 功能数组
    const allFunctionList = ref([]); //所有功能数组

    // 拼接所有菜单的选中功能
    const fnList = computed(() => {
      let arr = [];
      checkedFunctionList.value.map(item => {
        if (item.itemIdList) {
          arr = arr.concat(item.itemIdList);
        }
      });
      return arr;
    });

    onMounted(() => {});

    watch(
      () => visible.value,
      val => {
        if (val) {
          getRoleAuthById();
          getRoleResourceTreeDataById();
        }
      }
    );

    const showModal = (flag, rowData) => {
      visible.value = true;

      isEdit.value = flag;
      if (!flag) return;
      formState.roleName = rowData.roleName;
      formState.id = rowData.id;
    };

    // 选中一个及以上功能时，当前页面也要勾选
    const handleChangeCheckbox = (groupId, index) => {
      if (checkedFunctionList.value[index].itemIdList.length > 0) {
        selectedRowKeys.auth.push(groupId);
        selectedRowKeys.auth = [...new Set(selectedRowKeys.auth)];
        console.log('选中功能', selectedRowKeys.auth, groupId, index);
      }
      // else {
      //   selectedRowKeys.auth.splice(selectedRowKeys.auth.indexOf(groupId), 1);
      // }
    };

    // 根据角色 id 查询权限
    const getRoleAuthById = async () => {
      let params = {
        id: formState.id
      };
      console.log('params===', params);
      const { success, data } = await api.getRoleAuthById(params);
      if (success) {
        tableState.authTableData = data.authVoList;
        // 由于接口原因，资源分配的选中的 keys 也在此接口返回
        checkedKeys.value = data.resourceList;
        selectedRowKeys.auth = data.authCheckedId;

        data.authVoList.map((item, index) => {
          let arr = [];
          let arr1 = [];
          item.functionList.map(item1 => {
            if (item1.checked) {
              arr.push(item1.value);
            }
            arr1.push(item1.value);
          });
          allFunctionList.value[index] = {
            groupId: item.id,
            itemIdList: arr1
          };
          checkedFunctionList.value[index] = {
            groupId: item.id,
            itemIdList: arr
          };
        });
      }
    };

    // 根据角色 id 查询资源信息 树状结构
    const getRoleResourceTreeDataById = async () => {
      let params = {
        id: formState.id
      };
      const { success, data } = await api.getRoleResourceTreeDataById(params);
      if (success) {
        treeData.value = data;
      }
    };

    // 权限分配选择
    const onAuthChange = keys => {
      console.log('权限分配选择===', keys);
      selectedRowKeys.auth = keys;
    };

    const onAuthSelect = val => {
      // 如果当前选择的页面id存在于change的id list里面，证明是勾选
      if (selectedRowKeys.auth.includes(val.id)) {
        allFunctionList.value.map((item, index) => {
          // 勾选页面，同时勾选页面下所有功能
          if (item.groupId === val.id) {
            let page = allFunctionList.value.filter(item1 => item1.groupId === val.id);
            checkedFunctionList.value[index] = page[0];
          }
        });
      }
      // 否则是取消勾选
      else {
        //取消勾选页面，则同时取消勾选页面下面的所有功能
        allFunctionList.value.map((item, index) => {
          if (item.groupId === val.id) {
            checkedFunctionList.value[index] = {};
          }
        });
      }
    };

    const onCheckTree = (checkedKeys, info) => {
      console.log('onCheck', checkedKeys, info);
    };

    const handleOk = async () => {
      loading.value = true;
      console.log('abc=======', fnList.value);
      let params = {
        menuList: selectedRowKeys.auth.concat(fnList.value),
        resourceTypeList: checkedKeys.value,
        ...formState
      };
      console.log('params===', params);
      const functionName = isEdit.value ? 'updateAuthById' : 'addRole';
      let { success } = await api[functionName](params);
      if (success) {
        context.emit('addOrUpdateSuccess');
        message.success(`${isEdit.value ? '编辑' : '新增'}角色成功！`);
      }
      console.log('params=====', params);
      loading.value = false;
      visible.value = false;
    };

    const handleCancel = () => {
      visible.value = false;
      clearForm();
    };

    const clearForm = () => {
      Object.keys(formState).map(key => {
        formState[key] = '';
      });
      selectedRowKeys.auth = [];
      selectedRowKeys.resource = [];
      checkedFunctionList.value = [];
    };

    return {
      authColumns,
      authTableData,
      checkedFunctionList,
      allFunctionList,
      handleChangeCheckbox,
      replaceFields,
      treeData,
      isEdit,
      labelCol,
      wrapperCol,
      ...toRefs(formState),
      loading,
      visible,
      showModal,
      handleOk,
      handleCancel,
      checkedKeys,
      onCheckTree,
      onAuthChange,
      onAuthSelect,
      ModalBoxRef,
      clearForm,
      fnList,
      ...toRefs(tableState),
      ...toRefs(selectedRowKeys)
    };
  }
});
</script>

<style lang="less" scoped>
:deep(.ant-modal-body) {
  .title {
    font-size: 18px;
    font-weight: bold;
    text-align: center;
  }
  .ant-form {
    .ant-table {
      .ant-checkbox-group {
        display: flex;
      }
    }
  }
}
</style>
